body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  -webkit-appearance: none;
  -webkit-box-align: center;
  align-items: center;
  background-color: #eb6433;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  color: white;
  font-weight: 600 !important;
  display: -webkit-inline-box;
  display: inline-flex;
  font-size: 14px;
  height: 32px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  vertical-align: top;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-box-pack: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  white-space: nowrap;
}

button,
.input {
  border-radius: 0px !important;
}

.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-align: center;
  align-items: center;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  color: #000000;
  display: -webkit-inline-box;
  display: inline-flex;
  font-size: 14px;
  height: 32px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  vertical-align: top;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
}

input,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-align: center;
  align-items: center;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 0px;
  color: #000000;
  display: -webkit-inline-box;
  display: inline-flex;
  font-size: 14px;
  height: 32px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  vertical-align: top;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
}

input:focus {
  outline: none !important;
  border: 1px solid black;
  box-shadow: 0 0 1px #719ece;
}

.border-is-orange {
  border-color: #eb6433;
}
.is-orange {
  color: #eb6433;
}
.is-orange:hover {
  color: #eb6433;
}

.product-row-item {
  min-width: 85px;
  text-align: center;
}

.product {
  max-width: 234px !important;
}
.spin {
  width: 50px;
  height: 50px;
  animation-name: spin;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  /* transform: rotate(3deg); */
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#login-container {
}
#login {
  /*height:350px;*/
  width: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.separator {
  padding-left: 50px;
  padding-right: 50px;
}

#productDescription ul {
  list-style: inside;
}
#search:focus {
  border: none;
  box-shadow: none;
}
#search-button{
  outline: none;
}
.is-loading {
  color: transparent !important;
  pointer-events: none;
}

.is-loading:after {
  -webkit-animation: spinAround 500ms infinite linear;
  animation: spinAround 500ms infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 16px;
  position: relative;
  width: 16px;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  position: absolute !important;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.product-detail-add{
  width: 113px;
 }